.year-to-date-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

/* Mobile View */
.mobile-view {
    display: none;
    padding: 0.5rem;
}

.mobile-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.mobile-summary:hover {
    background-color: #e9ecef;
}

.mobile-summary-content {
    flex: 1;
}

.expand-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6c757d;
    font-size: 0.85rem;
}

.expand-button i {
    font-size: 0.8rem;
}

.expand-text {
    text-decoration: underline;
}

.fy-label {
    font-weight: 600;
    color: #495057;
    font-size: 1rem;
    margin-bottom: 0.25rem;
}

.net-position {
    font-weight: 600;
    font-size: 1.1rem;
}

.mobile-details {
    padding: 1rem;
    border-top: 1px solid #eee;
    animation: slideDown 0.3s ease;
}

@keyframes slideDown {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

.mobile-section {
    margin-bottom: 1rem;
}

.mobile-section:last-child {
    margin-bottom: 0;
}

.section-title {
    font-weight: 600;
    color: #495057;
    margin-bottom: 0.5rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.35rem 0;
    font-size: 1rem;
    gap: 0.5rem;
}

.detail-row span:first-child {
    color: #495057;
    font-weight: 500;
    position: relative;
    padding-right: 1rem;
}

.detail-row span:first-child::after {
    content: ':';
    position: absolute;
    right: 0;
}

.detail-row span:last-child {
    text-align: right;
    min-width: 120px;
    font-weight: 600;
}

/* Desktop View */
.desktop-view {
    padding: 0.5rem 2rem;
    background-color: #f8f9fa;
}

.desktop-summary {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.desktop-totals {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
    flex: 1;
    justify-content: center;
}

.total-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 230px;
}

.total-item.net {
    min-width: 200px;
    padding-left: 1.2rem;
    border-left: 1px solid #dee2e6;
}

.total-label {
    font-size: 1.1rem;
    color: #495057;
    margin-bottom: 0.4rem;
    font-weight: 600;
}

.total-amount {
    font-size: 1rem;
    color: #495057;
    margin-bottom: 0.25rem;
    line-height: 1.2;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 0.3rem;
}

.total-amount .label {
    position: relative;
    padding-right: 0.5rem;
}

.total-amount .label::after {
    content: ':';
    position: absolute;
    right: 0;
}

.total-amount .value {
    text-align: right;
    min-width: 110px;
}

.total-amount:last-child {
    margin-bottom: 0;
}

.total-amount span.positive,
.total-amount span.negative {
    font-weight: 600;
}

/* Remove unused styles */
.total-gst, .total-net {
    display: none;
}

/* Colors */
.positive {
    color: #28a745;
}

.negative {
    color: #dc3545;
}

.neutral {
    color: #6c757d;
}

/* Special color for Income less Expenses */
.total-item.net .total-amount.positive {
    color: #4527a0;  /* Dark purple */
}

.total-item.net .total-amount.negative {
    color: #7b1fa2;  /* Dark purple-red */
}

/* Responsive Design */
@media (max-width: 1200px) {
    .desktop-totals {
        gap: 1.5rem;
    }

    .total-item {
        min-width: 200px;
    }

    .total-item.net {
        min-width: 180px;
        padding-left: 1rem;
    }
}

@media (max-width: 768px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }
}

/* For the Income less Expenses total */
.total-item.net .total-amount {
    font-weight: 700;
    font-size: 1.15rem;
} 