.loader-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 100%; */
  height: 100vh;
  position: fixed;
  z-index: 11111;
  background-color: rgb(205 205 205 / 30%);
  /* filter: blur(); */
}
.body {
  background-color: rgba(117, 117, 117, 0.199);
}
.custom-loader {
  position: absolute;
  justify-content: center;
  width: 50px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(91.33deg, #005292 0%, #002f82 100.2%);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
