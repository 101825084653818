/* Style for the form container */
.systemSetUp-form {
  border-radius: 16px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 1);
}

/* Style for the submit button */
.submit-btn {
  width: 288px;
  height: 44px;
  border-radius: 6px;
  font-family: "Ubuntu", sans-serif;
  font-size: 15.7px;
  font-weight: 800;
  line-height: 19.14px;
  text-align: center;
  color: white;
  margin-top: 10px;
  background: linear-gradient(91.33deg, #005292 0%, #002f82 100.2%);
  border: none;
}

/* Style for the heading text */
.heading {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18.38px;
  text-align: left;
}

/* Google Places Autocomplete Styles */
.pac-container {
  background-color: #fff;
  position: fixed !important;
  z-index: 9999;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 2px;
}

.pac-item {
  cursor: pointer;
  padding: 8px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
  color: #515151;
  display: block !important;
}

.pac-item:hover {
  background-color: #f5f5f5;
}

.pac-item-selected {
  background-color: #ebf2fe;
}

.pac-matched {
  font-weight: bold;
}
