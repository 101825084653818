.form-check-input:checked {
  background-color: #00429b !important;
  border-color: #00429b;
}
.custom-style {
  padding: 7px 15px;
  background-color: #00429b !important;
  border: #00429b !important;
}

.custom-btn {
  border-color: #00429b !important;
  color: #00429b !important;
  background: white !important;
}
.modal-footer {
  border-top: none !important;
}
.custom-btn-save {
  padding: 7px 35px !important;
  background-color: #00429b !important;
  border: #00429b !important;
}
.custom-label {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px !important;
  text-align: left;
}
.custom-input-user-popup {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}
select option:hover {
  background-color: yellow;
  color: black;
}
.modal-header {
  border-bottom: 1px solid #00429b !important;
}
hr.border-top-only {
  border: none;
  opacity: 3;
  border-top: 1px solid #00429b !important;
  margin: 0px 16px;
}
.modal-title {
  font-weight: bold !important;
  font-size: 24px;
}

.border-bottom-only {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 0.5px solid #dddddd !important;
}
.profile-name {
  color: #1f2937;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.profile-email {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6b7280;
}
#description {
  padding: 12px;
  font-size: 15.7px;
  box-shadow: 0px 2.24px 4.49px 0px #00000040;
}
textarea::placeholder {
  font-size: 15.7px;
  font-weight: 400;
  line-height: 18.04px;
  text-align: left;
  color: #bbbbbb;
}

/* Enhanced Collapsible Section Styles with better visual cues */
.section-header {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  padding: 1rem;
}

.section-header:hover {
  background: #f9fafb;
  border-color: #00429b;
}

.section-header:active {
  background: #f3f4f6;
}

/* Make the expand/collapse more obvious */
.expand-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f3f4f6;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.section-header:hover .expand-indicator {
  background: #e5e7eb;
}

.expand-text {
  font-size: 0.875rem;
  color: #6b7280;
  user-select: none;
}

.expand-icon {
  color: #00429b;
  font-size: 0.875rem;
  transition: transform 0.3s ease-in-out; /* Smooth animation */
}

/* Rotate when expanded */
.section-header[aria-expanded="true"] .expand-icon {
  transform: rotate(360deg);
}

.section-header[aria-expanded="true"] {
  background: #f8f9fa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #00429b;
}

.custom-label-title {
  font-weight: 600;
  color: #374151;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Add a pulsing animation to hint at clickability when first shown */
@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 66, 155, 0.4);
  }
  70% {
    box-shadow: 0 0 0 4px rgba(0, 66, 155, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 66, 155, 0);
  }
}

.section-header:not([aria-expanded="true"]) {
  animation: pulse-border 2s infinite;
}

/* Count badge styling */
.section-header .text-muted {
  font-size: 0.875rem;
  color: #6b7280;
  background: #f3f4f6;
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px solid #e5e7eb;
}

/* Content area styling */
.collapse {
  background: #ffffff;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.3s ease-out;
}

.collapse .pt-3 {
  padding: 1.5rem;
}

/* Smooth collapse animation */
.collapse {
  transition: all 0.2s ease-out;
}

.collapse:not(.show) {
  display: none;
}

.collapse.show {
  display: block;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2)
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

/* Custom gradient button adjustments */
.custom-gradient-btn {
  margin-right: 12px;
  background: linear-gradient(90deg, #00429b 0%, #006fba 100%);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

/* Delete button styling */
.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
  transition: all 0.3s ease;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

/* Add some spacing between items */
.row-gap-3 {
  row-gap: 1rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

/* Spinner styles */
.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
