/* ----------------------------------------
   COMMON STYLES
---------------------------------------- */

/* Error Text */
.error-text {
  color: red;
  font-size: 12px;
  margin-bottom: 0;
}

/* Mandatory Asterisk */
.mandatory::after {
  content: "*";
  color: red;
}

.custom-pointer {
  cursor: pointer;
}

.date-input-color {
  color: rgba(135, 142, 156, 1) !important;
}

.default {
  cursor: default !important;
}

/* Gradient Text */
.gradient-text {
  background: linear-gradient(to right bottom, #003d7d, #add6ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.p-column-title {
  font-size: 14px;
  white-space: nowrap;
}

.p-datatable-tbody {
  font-size: 12px;
}

/* Common Input Focus Styles */
input:focus-visible,
.form-control:focus,
.form-select:focus,
.custom-select-popup:focus-visible {
  outline: none;
}

.form-control:focus,
.form-select:focus {
  border-color: none !important;
}

.custom-hr {
  width: 87%;
  border-top: 1px solid rgb(83, 83, 83);
}

/* Disabled Inputs */
.disable-profile-input {
  display: block;
  padding: 0px;
  color: #5d5d5d;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 44px;
  border-radius: 6px;
  font-size: 14.7px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left;
  border: none;
}

.disable-profile-input:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

/* Custom Box Shadow */
.custom-box-shadow {
  background-color: white;
  box-shadow: 0px 4px 9px 0px rgba(152, 160, 180, 0.25);
  margin-bottom: 10px;
  border-radius: 8px;
}

/* Centered File Input Button */
.centered-file-input::file-selector-button {
  width: 53%;
  text-align: left;
  height: 40px;
}

/* Custom Input and Select */
.custom-input-popup,
.custom-select-popup {
  width: 288px;
  height: 44px;
  border-radius: 6px;
  border: 0.56px solid rgba(242, 242, 242, 1);
  font-size: 14.7px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left;
  padding: 5px 10px !important;
  box-shadow: 0px 2.24px 4.49px 0px rgba(0, 0, 0, 0.25) !important;
  color: rgba(135, 142, 156, 1);
}

.custom-input-popup:focus,
.custom-select-popup:focus {
  border-color: none !important;
}

.custom-select-popup option {
  color: rgb(15, 15, 15) !important;
}

.custom-description {
  height: 134px;
  resize: none;
}

.p-dropdown-label {
  border: none !important;
}

ul {
  padding: 0px !important;
}

/* Form Select and Control */
.form-control:focus-visible {
  outline: none;
}

.page-not-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Modal Styles */
.modal-dialog {
  border-radius: 16px !important;
}

.modal-content {
  border-radius: 16px !important;
}

@media (min-width: 1024px) {
  .modal .modal-dialog {
    max-width: 55% !important;
  }
}

@media (min-width: 1440px) {
  .modal .modal-dialog {
    max-width: 45% !important;
  }
}

.modal-footer.trasnaction-modal {
  justify-content: space-between;
}

/* ----------------------------------------
   BUTTON STYLES
---------------------------------------- */

/* Custom Gradient Button */
.custom-gradient-btn {
  background: linear-gradient(91.33deg, #005191 0%, #002f82 100.2%);
  color: white;
  font-weight: bold;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 4.8px;
  cursor: pointer;
  border: none;
}

/* Custom Ghost Button */
.custom-ghost-btn {
  border: 1.6px solid #005192;
  color: rgba(0, 66, 130, 1);
  font-weight: bold;
  padding: 10px 12px;
  background-color: white;
  font-size: 14px;
  border-radius: 4.8px;
  cursor: pointer;
}

/* ----------------------------------------
   LAYOUT STYLES
---------------------------------------- */

/* Background Images */
.bg-image,
.bg-image-white {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.bg-image {
  background-image: url("../../assets/bg-image/bg-image.svg");
}

.bg-image-white {
  background-image: url("../../assets/bg-image/bg-image-home.svg");
}

/* Custom Column Class */
.col-custom-md-3 {
  flex: 0 0 auto;
  width: 22%;
}

/* Navbar */
.custom-navbar {
  height: 58px;
  background: linear-gradient(90.29deg, #002263 0%, #004282 100%);
}

/* Profile Nav Name */
.profile-nav-name {
  font-size: 17.39px;
  font-weight: 400;
  line-height: 19.98px;
  text-align: center;
  color: white;
}

/* Logout Image Rotation */
.logout-image {
  transform: rotate(180deg);
}

.login-container {
  border-radius: 6px 0 0 0;
  margin: 20px 0 !important;
}

/* ----------------------------------------
   POPUP STYLES
---------------------------------------- */

/* Modal Label */
.custom-label-popup {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 17px !important;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 6px !important;
}

.custom-label-title {
  font-size: 16px !important;
  padding: 0px !important;
  font-weight: 500;
  text-align: left;
  color: #1e1e1e;
}

/* ----------------------------------------
   SCROLL BAR
---------------------------------------- */
/* Target the scrollable area of the Data */
/* Style the vertical scrollbar */

/* Style the vertical scrollbar */
::-webkit-scrollbar {
  width: 10px;
  /* Set width for vertical scrollbar */
  height: 10px;
}

/* Style the track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f2f4f8;
  /* Light grey for the track */
  border-radius: 10px;
  /* Rounded corners for the track */
}

/* Style the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  height: 2px;
  background: linear-gradient(91.33deg, #005191 0%, #002f82 100.2%);
  border-radius: 10px;
  /* Rounded corners for the thumb */
  border: 3px solid #f1f1f1;
  /* Add some space between the thumb and track */
}

/* Hover state for the thumb */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(91.33deg, #005191 0%, #002f82 100.2%);
}

/* ----------------------------------------
  custom css for Alert
---------------------------------------- */
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background: linear-gradient(91.33deg, #005191 0%, #002f82 100.2%) !important;
  color: white;
  font-weight: bold;
  padding: 10px 12px;
  font-size: 14px;
  border-radius: 4.8px;
  cursor: pointer;
  border: none;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm):focus-visible {
  box-shadow: none !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  z-index: 111111 !important;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
}

.label-class {
  margin: 5px;
  background-color: #002f82;
  padding: 2.5px;
  cursor: pointer;
}

.input-group-text {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem !important;
  padding: 0.375rem 1.5rem !important;
  background: #fff;
}