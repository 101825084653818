/* ================================================================================= */
/* ================================== Media Query For all screen =================================== */
/* ================================================================================= */

@media (min-width: 300px) and (max-width: 335px) {
  .overview-title {
    width: 100vw !important;
    margin-bottom: 10px;
  }
  .overview-line {
    width: 100vw !important;
  }
}
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .overview-title {
    width: 100vw !important;
    margin-bottom: 10px;
  }
  .overview-line {
    width: 92vw !important;
  }
  .filter-section.open {
    max-height: 1260px !important;
  }
  .custom-select-popup {
    width: 100%;
  }
  .btn-center {
    width: 100% !important;
    margin-top: 10px !important;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .overview-title {
    width: 100vw !important;
    margin-bottom: 10px;
  }
  .overview-line {
    width: 100vw !important;
  }
  .filter-section.open {
    max-height: 560px !important;
  }
}

/* Medium devices (tablets, portrait iPads, 768px and up) */
@media (min-width: 768px) and (max-width: 1023.98px) {
  .custom-select-popup {
    width: 93%;
  }
}
/* Portrait orientation iPads */
@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* Landscape orientation iPads */
@media (min-device-width: 768px) and (max-device-width: 1400px) and (orientation: landscape) {
}
/* Large devices (landscape iPads and desktops, 1024px and up) */
@media (min-width: 1024px) and (max-width: 1199.98px) {
  .custom-select-popup {
    width: 90%;
  }
}

@media (min-width: 1199.98px) and (max-width: 1440px) {
  .custom-select-popup {
    width: 90%;
  }
}

@media (max-width: 2560px) {
  .custom-select-popup {
    width: 95%;
  }
  .btn-center {
    width: 95% !important;
  }
}

@media (min-width: 2560px) {
  .custom-select-popup {
    width: 97%;
  }
  .btn-center {
    width: 97% !important;
  }
}
/* Extra large devices (large desktops, 1440px and up) */

/* @media (min-width: 768px) {
  .col-custom-md-3 {
    flex: 0 0 auto;
    width: 22%;
  }
} */
