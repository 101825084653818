/* data */
.search-btn-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.09px;
  text-align: right;
}
.search-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------------------
   Custome DATA TABLE
---------------------------------------- */
.p-datatable-wrapper {
  background-color: white;
  box-shadow: 0px 4px 9px 0px rgba(152, 160, 180, 0.25);
  border-radius: 8px 0px 8px 0px;
}
.card {
  border: none !important;
}
.p-datatable-header {
  background: none;
  border: none;
  border-width: 0px 0 0px 0;
  padding: 0rem 0rem;
}
.p-paginator {
  background-color: white;
  box-shadow: 0px 4px 9px 0px rgba(152, 160, 180, 0.25);
  border-radius: 0px 8px 0px 8px;
}
.p-inputtext {
  font-size: 13px;
  border: 1px solid #cdcdcd;
}
.p-inputtext:enabled:hover {
  border-color: #cdcdcd;
}
