/* Overview title styling */
.overview-title {
  font-size: 1.8rem;
  color: rgba(14, 14, 14, 1);
  font-weight: bold;
  width: 21vw;
}

/* Line below the title */
.overview-line {
  height: 2px;
  width: 75vw;
  background: linear-gradient(to right, #005192 0%, #003082);
}

/* Collapsible filter section */
.filter-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out,
    margin 0.5s ease-out, opacity 0.5s ease-out, transform 0.5s ease-out;
  background-color: white;
  padding: 20px;
  /* Initial padding */
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Open filter section */
.filter-section.open {
  max-height: 500px;
  /* Adjust as needed */
  padding: 16px;
  /* Keep the padding when open */
}

/* Closed filter section */
.filter-section.closed {
  max-height: 0;
  padding: 0;
  /* Set padding to 0 when closed */
}

/* Filter title styling */
.filter-title {
  font-weight: bold;
  font-size: 18px;
  font-weight: 500;
  line-height: 20.68px;
  text-align: left;
  color: #003366;
  padding: 10px 15px;
  cursor: pointer;
}

/* Filter row layout */
.filter-row {
  display: flex;
  gap: 0px;
  padding: 10px 15px;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 15px;
}

/* Button styling */
.btn-center {
  width: 90%;
  margin-top: 28px;
  height: 36px;
}

/* Label text styling */
.label-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  margin-bottom: 10px;
}

.label-text.large {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}